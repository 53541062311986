import { Navigate, Outlet } from "react-router-dom";
import {
  ACCEUIL,
  APPELS_PATH,
  BULLETIN_PATH,
  CLASSES_PATH,
  COACHS_PATH,
  COMPOSITION_PATH,
  DIAGNOSTIQUES_PARENT_PATH,
  DIAGNOSTIQUES_PATH,
  ECOLES_PATH,
  ELEVES_PATH,
  FICHES_PATH,
  LOGIN,
  MATIERES_PATH,
  NIVEAUX_PATH,
  PAIEMENTS_PATH,
  PARENTS_PATH,
  PARTENAIRE_PATH,
  PROSPECTS_PATH,
  VILLES_PATH,
} from "../utils/navigationPaths";
import ElevePage from "../pages/eleve/ElevePage";
import CoachPage from "../pages/coach/CoachPage";
import EcolePage from "../pages/ecole/EcolePage";
import FichePage from "../pages/fiches/FichePage";
import AppelPage from "../pages/appel/AppelPage";
import PaiementPage from "../pages/paiement/PaiementPage";
import LoginPage from "../pages/login/LoginPage";
import VillePage from "../pages/ville/VillePage.jsx";
import ParentPage from "../pages/parent/ParentPage";
import DiagnostiquePage from "../pages/diagnostiques/DiagnostiquePage";
import DiagnostiqueListe from "../pages/diagnostiques/DiagnostiqueListe";
import EpreuvePage from "../pages/diagnostiques/epreuves/EpreuvePage";
import DiagnosticParentPage from "../pages/diagnostiques/parents/DiagnosticParentPage";
import DiagnostiqueParentListe from "../pages/diagnostiques/parents/DiagnostiqueParentList";
import DiagnostiqueQuizzForm from "../pages/diagnostiques/parents/quizz/DiagnostiqueQuizzForm";
import DiagnostiqueQuizzList from "../pages/diagnostiques/parents/quizz/DiagnostiqueQuizzList";
import DiagnostiqueQuizzDetails from "../pages/diagnostiques/parents/quizz/DiagnostiqueQuizzDetails";
import DiagnostiqueListeDetails from "../pages/diagnostiques/DiagnostiqueListeDetails";
import EleveDetailsPage from "../pages/eleve/ui/EleveDetailsPage";
import DashboardPage from "../pages/dasboard/DashboardPage";
import BulletinPage from "../pages/bulletin/BulletinPage";
import BulletinListByClasse from "../pages/bulletin/ui/BulletinListByClasse";
import BulletinEleve from "../pages/bulletin/ui/BulletinEleve";
import EcoleListePage from "../pages/ecole/ui/EcoleListePage";
import EcoleDirecteurPage from "../pages/ecole/ui/EcoleDirecteurPage";
import EcoleMaitrePage from "../pages/ecole/ui/EcoleMaitrePage";
import AppelCreatePage from "../pages/appel/ui/AppelCreatePage";
import BulletinNote from "../pages/bulletin/ui/BulletinNote";
import ClassePage from "../pages/ecole/ui/classe/ClassePage";
import MatierePage from "../pages/ecole/ui/matiere/MatierePage";
import NiveauxPage from "../pages/ecole/ui/niveaux/NiveauxPage";
import CompositionPage from "../pages/composition/CompositionPage";
import CompositionLIstePage from "../pages/composition/ui/CompositionLIstePage";
import ParentDiagnosticPage from "../pages/diagnostiques/parent/ParentDiagnosticPage";
import FicheAjoutPage from "../pages/fiches/FicheAjoutPage";
import FicheDetailsPage from "../pages/fiches/components/FicheEdit";
import EpreuveDetailPage from "../pages/diagnostiques/epreuves/EpreuveDetails";
import ParrainPage from "../pages/ecole/ui/ParrainPage";
import EpreuveByNiveauPage from "../pages/diagnostiques/epreuves/EpreuveByNiveau";
import ListeCoachsFiche from "../pages/fiches/ui/ListeCoachsFiche.jsx";
import CaochDetailsPage from "../pages/coach/ui/CaochDetailsPage.jsx";
import CoachSuiviPage from "../pages/coach/ui/CoachSuiviPage.jsx";
import ProspectsIndexPage from "../pages/prospects/ProspectIndexPage.jsx";
import CoachAppelDetails from "../pages/coach/ui/CoachAppelDetailsPage.jsx";
import EleveDiagnostiqueNotePage from "../pages/eleve/ui/EleveDiagnostiqueNotePage.jsx";
import ProspectPage from "../pages/prospects/ProspectPage.jsx";
import ContactPage from "../pages/prospects/ContactPage.jsx";
import PaiementParentPage from "../pages/paiement/PaiementParentPage.js";
import PaiementElevePage from "../pages/paiement/PaiementElevePage.js";
import PartenaireHomePage from "../pages/partenaires/pages/home/PartenaireHomePage.jsx";
import PartenaireParentPage from "../pages/partenaires/pages/parent/PartenaireParentPage.jsx";
import ParraintPage from "../pages/parraint/ParraintPage.jsx";

export const publicRoutes = [
  {
    path: "*",
    element: <Navigate to={LOGIN} />,
  },
  {
    path: "/",
    element: <Navigate to={LOGIN} />,
  },
  {
    path: LOGIN,
    element: <LoginPage />,
  },
];

export const privateRoutes = [
  {
    path: "/admin",
    element: <Navigate to={ACCEUIL} />,
  },
  {
    path: ACCEUIL,
    element: <DashboardPage />,
  },
  {
    path: ELEVES_PATH,
    element: <Outlet />,
    subPath: [
      {
        path: "",
        element: <ElevePage />,
      },
      {
        path: ":id",
        element: <Outlet />,
        subPath: [
          {
            path: "",
            element: <EleveDetailsPage />,
          },
          {
            path: "notes",
            element: <EleveDiagnostiqueNotePage />,
          },
        ],
      },
    ],
  },
  {
    path: COACHS_PATH,
    element: <Outlet />,
    subPath: [
      {
        path: "",
        element: <CoachPage />,
      },
      {
        path: "suivi",
        element: <CoachSuiviPage />,
      },
      {
        path: ":id",
        element: <Outlet />,
        subPath: [
          {
            path: "",
            element: <CaochDetailsPage />,
          },
          {
            path: "appels",
            element: <CoachAppelDetails />,
          },
        ],
      },
    ],
  },
  {
    path: PARENTS_PATH,
    element: <ParentPage />,
  },
  {
    path: "partenaires",
    element: <ParraintPage />,
  },
  {
    path: PROSPECTS_PATH,
    element: <ProspectsIndexPage />,
    subPath: [
      {
        path: "",
        element: <ProspectPage type={"CONTACT"} />,
      },
      {
        path: "prospsects",
        element: <ProspectPage type={"PROSPECT"} />,
      },
      {
        path: "clients",
        element: <ProspectPage type={"CONFIRMED"} />,
      },
      {
        path: "messages",
        element: <ContactPage />,
      },
    ],
  },
  {
    path: ECOLES_PATH,
    element: <EcolePage />,
    subPath: [
      {
        path: "",
        element: <EcoleListePage />,
      },
      {
        path: "directeur",
        element: <EcoleDirecteurPage />,
      },
      {
        path: "maitre",
        element: <EcoleMaitrePage />,
      },
      {
        path: "parrains",
        element: <ParrainPage />,
      },
      {
        path: CLASSES_PATH,
        element: <ClassePage />,
      },
      {
        path: MATIERES_PATH,
        element: <MatierePage />,
      },
      {
        path: NIVEAUX_PATH,
        element: <NiveauxPage />,
      },
    ],
  },
  // {
  //   path: CLASSES_PATH,
  //   element: <ClassePage />,
  // },
  {
    path: FICHES_PATH,
    element: <Outlet />,
    subPath: [
      {
        element: <FichePage />,
        path: "",
      },
      {
        element: <FicheDetailsPage />,
        path: ":id",
      },
      {
        element: <ListeCoachsFiche />,
        path: "coach/:id",
      },
      {
        element: <FicheAjoutPage />,
        path: "ajout",
      },
    ],
  },
  // {
  //   path: MATIERES_PATH,
  //   element: <MatierePage />,
  // },
  // {
  //   path: NIVEAUX_PATH,
  //   element: <NiveauxPage />,
  // },
  {
    path: VILLES_PATH,
    element: <VillePage />,
  },
  {
    path: APPELS_PATH,
    element: <Outlet />,
    subPath: [
      {
        path: "",
        element: <AppelPage />,
      },
      {
        path: "create",
        element: <AppelCreatePage />,
      },
    ],
  },
  {
    path: PAIEMENTS_PATH,
    element: <PaiementPage />,
    subPath: [
      { path: "", element: <PaiementElevePage /> },
      { path: "parent", element: <PaiementParentPage /> },
    ],
  },
  {
    path: DIAGNOSTIQUES_PATH,
    element: <DiagnostiquePage />,
    handle: {
      // `crumb` is your own abstraction, we decided
      // to make this one a function so we can pass
      // the data from the loader to it so that our
      // breadcrumb is made up of dynamic content
      crumb: () => <span>Diagnostics</span>,
    },
    subPath: [
      {
        path: "",
        element: <Outlet />,
        subPath: [
          {
            path: "",
            element: <DiagnostiqueListe />,
            handle: {
              // `crumb` is your own abstraction, we decided
              // to make this one a function so we can pass
              // the data from the loader to it so that our
              // breadcrumb is made up of dynamic content
              crumb: () => <span>Diagnostics</span>,
            },
          },
          {
            path: ":id",
            element: <Outlet />,
            subPath: [
              {
                path: "",
                element: <DiagnostiqueListeDetails />,
                handle: {
                  // `crumb` is your own abstraction, we decided
                  // to make this one a function so we can pass
                  // the data from the loader to it so that our
                  // breadcrumb is made up of dynamic content
                  crumb: () => <span>Liste niveux</span>,
                },
              },
              {
                path: ":id",
                element: <Outlet />,
                subPath: [
                  {
                    path: "",
                    element: <EpreuveByNiveauPage />,
                    handle: {
                      // `crumb` is your own abstraction, we decided
                      // to make this one a function so we can pass
                      // the data from the loader to it so that our
                      // breadcrumb is made up of dynamic content
                      crumb: () => <span>Liste des exercices</span>,
                    },
                  },
                  {
                    path: ":id",
                    element: <EpreuveDetailPage />,
                    handle: {
                      // `crumb` is your own abstraction, we decided
                      // to make this one a function so we can pass
                      // the data from the loader to it so that our
                      // breadcrumb is made up of dynamic content
                      crumb: () => <span>Details epreuve</span>,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      { path: "parent", element: <ParentDiagnosticPage /> },
      {
        path: "epreuves",
        element: <Outlet />,
        subPath: [
          {
            path: "",
            element: <EpreuvePage />,
          },
          {
            path: ":id",
            element: <EpreuveDetailPage />,
          },
        ],
      },
      {
        path: BULLETIN_PATH,
        element: <Outlet />,
        subPath: [
          {
            element: <BulletinPage />,
            path: "",
          },
          {
            path: ":id",
            element: <Outlet />,
            subPath: [
              {
                path: "",
                element: <BulletinListByClasse />,
              },
              {
                path: ":studentId",
                element: <Outlet />,
                subPath: [
                  {
                    path: "",
                    element: <BulletinEleve />,
                  },
                  { path: "eleve", element: <BulletinNote /> },
                ],
              },
            ],
          },
        ],
      },
      { path: "autres", element: "Autre Choses" },
    ],
  },
  {
    path: DIAGNOSTIQUES_PARENT_PATH,
    element: <DiagnostiqueParentListe />,
    subPath: [
      { path: "", element: <DiagnosticParentPage /> },
      {
        path: "quizz",
        element: <Outlet />,
        subPath: [
          { path: "", element: <DiagnostiqueQuizzList /> },
          { path: "add", element: <DiagnostiqueQuizzForm /> },
          { path: ":id", element: <DiagnostiqueQuizzDetails /> },
        ],
      },
      { path: "maths", element: <DiagnostiqueQuizzForm /> },
    ],
  },
  {
    element: <Outlet />,
    path: COMPOSITION_PATH,
    subPath: [
      {
        element: <CompositionPage />,
        path: "",
      },
      {
        element: <CompositionLIstePage />,
        path: ":id",
      },
    ],
  },

  // {
  //   path: PARTENAIRE_PATH,
  //   element: <Outlet />,
  //   subPath: [
  //     {
  //       path: "",
  //       element: <PartenaireHomePage />,
  //     },
  //     {
  //       path: "parent",
  //       element: <PartenaireParentPage />,
  //     },
  //   ],
  // },
];

/*
 ***************************************
 * PARTENAIRE ROUTES
 ***************************************
 */

export const privatePartenaireRoutes = [
  {
    path: "/partenaire",
    element: <Navigate to={PARTENAIRE_PATH} />,
  },
  {
    path: PARTENAIRE_PATH,
    element: <PartenaireHomePage />,
  },
  {
    path: "parents",
    element: <PartenaireParentPage />,
  },
];
