import { Breadcrumbs } from '@mantine/core'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

export default function CustomBreadcrumbs() {

    const location = useLocation()
    let currentLink = ''
    const crumbs = location.pathname.split("/")
        .filter(crumb => crumb !== '')
        .map(crumb=>{
            currentLink += `/${crumb}`
            return (
                    <Link to={currentLink}>
                        {crumb.replaceAll("%20"," ").toLowerCase()}
                    </Link>
            )
        })

    return (
        <div>
            {/* {crumbs} */}
            <Breadcrumbs separator=">" mt="xs">{crumbs}</Breadcrumbs>
        </div>
    )
}
