import React from "react";
import Divider from "../../components/Divider";
import { Outlet } from "react-router-dom";
import TabViews from "../../components/TabViews";
import { Text } from "@mantine/core";
import { BULLETIN_PATH } from "../../utils/navigationPaths";
import Breadcrumbs from "../../components/Breadcrumbs";

const tabs = [
  { label: "Liste des diagnostiques", path: "" },
  { label: "Exercices parents", path: "parent" },
  { label: "Exercices coachs", path: "epreuves" },
  { label: "Liste des notes", path: BULLETIN_PATH },
  // { label: "autre choses", path: "autres" },
];

function DiagnostiquePage() {
  // const [active, setActive] = useState(0);

  // const navigation = useNavigate();

  return (
    <div>
      <Text size={20}>La liste des diagnostics</Text>
      <TabViews tabs={tabs} />
      <Breadcrumbs />
      <Divider my={20} />
      <div className="">
        <Outlet />
      </div>
    </div>
  );
}

export default DiagnostiquePage;
